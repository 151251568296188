<template>
  <v-container fluid>
    <v-tabs v-model="tabIndex">
      <v-tab v-for="(item, i) in tabs" :key="i">
        {{ item }}
      </v-tab>
    </v-tabs>
    <v-tabs-items v-model="tabIndex" :class="$vuetify.breakpoint.smAndDown ? 'px-0 mt-8' : 'pa-8'">
      <v-tab-item class="d-flex">
        <v-row style="width: 100%">
          <v-col cols="12" class="d-flex justify-end">
            <v-btn elevation="0" class="text-capitalize rounded-pill font-weight-bold" :color="color.secondary"
              @click="(dialog = true), cleanForm()">
              <v-icon>mdi-plus</v-icon>
              Agregar Beneficio
            </v-btn>
          </v-col>
          <v-col :cols="$vuetify.breakpoint.smAndDown
        ? 12
        : $vuetify.breakpoint.lgAndDown
          ? 12
          : 4
      " v-for="(item, i) in benefitsList" :key="i">
            <benefitsCard :name="item.name" :description="item.description" :icon="item.icon" @edit="handleEdit(item)"
              @delete="
      (deleteSwitch = true), (dialog = true), (temporalId = item._id)
      " />
          </v-col>
        </v-row>
      </v-tab-item>
    </v-tabs-items>
    <PersonalizedDialog :dialog="dialogComputed" :hideImage="!deleteSwitch"
      :disableContinueButton="deleteSwitch ? false : !formValid" @discard="(dialog = false), (deleteSwitch = false)"
      @save="
      editComputed
        ? editBenefit()
        : deleteSwitch
          ? deleteRegist(temporalId)
          : createNewBenefit()
      ">
      <template #alertName>
        {{ deleteSwitch ? "Eliminar beneficio." : "Crear nuevo beneficio" }}
      </template>
      <template #alertContent>
        <div v-if="deleteSwitch" class="text-center">
          <p>Seguro que quieres eliminar los datos de este beneficio?</p>
        </div>
        <div v-else class="d-flex justify-center flex-column">
          <p class="text-center ma-0 mb-4">
            Carga aquí la información de tu nuevo beneficio. Recomendamos que
            las dimensiones de la imagen sean como mínimo de 250 x 250 pixeles
          </p>
          <v-form v-model="formValid">
            <div v-for="field in form" :key="field.id" class="text-center">
              <ImageUploader v-if="field.id == 'icon'" :imgUrl="field.value != null
        ? field.value
        : '@/assets/images/weChamber/wechamber-blue-logo-placeholder.png'
      " @save="handleIcon" />
              <v-text-field v-else-if="!['expiredDate', 'description'].includes(field.id)" :label="field.label" rounded
                :rules="[field.rule]" background-color="#f2f2f2" v-model="field.value">
              </v-text-field>
              <v-textarea v-else-if="field.id == 'description'" rounded auto-grow background-color="#f2f2f2"
                v-model="field.value" :label="field.label" :rows="1" :rules="[field.rule]" :counter="150">
              </v-textarea>
              <v-menu v-else style="background: #f2f2f2" :close-on-content-click="true" transition="scale-transition"
                offset-y min-width="auto">
                <template #activator="{ on, attrs }">
                  <v-text-field v-model="field.value" :label="field.label" persistent-hint readonly rounded min
                    v-on="on" background-color="#f2f2f2" append-icon="mdi-calendar" v-bind="attrs" />
                </template>
                <v-date-picker v-model="field.value" no-title scrollable :min="minDate" locale="es">
                </v-date-picker>
              </v-menu>
            </div>
            <div class="text-center mt-4">
              <p>
                ¿Desea enviar una notificación por email a los miembros de la
                organización?
              </p>
              <v-radio-group v-model="selectedOption" row>
                <v-radio label="Sí, deseo enviar notificación vía email a mis miembros" value="yes"
                  @change="onOptionChange"></v-radio>
                <v-radio label="Cancelar" value="no"></v-radio>
              </v-radio-group>
            </div>
            <PersonalizedDialog hidden :hideImage="true" :dialog="confirmDialog2" :dimensions="{ width: 1113 }"
              @save="confirmYes" @discard="confirmNo">
              <template #alertName> Confirmación </template>
              <template #alertContent>
                <div class="d-flex justify-center" width="100%">
                  ¿Está seguro de que desea enviar la notificación?
                </div>
              </template>
            </PersonalizedDialog>
            <div v-if="selectedOption === 'yes'">
              <v-combobox v-model="selectedSubgroup" label="¿A que grupo de miembros deseas enviar notificaciones?"
                :items="subgroups" background-color="#f9fafc" persistent-hint hide-details filled :multiple="false"
                hide-selected>
              </v-combobox>
            </div>
          </v-form>
        </div>

      </template>
    </PersonalizedDialog>
  </v-container>
</template>

<script>
import axios from "axios";
import { colors } from "@/constants/colors.js";
import benefitsCard from "@/components/dashboard/Benefits/benefitsCard";
import PersonalizedDialog from "@/components/shared/PersonalizedDialog";
import { mapActions, mapMutations } from "vuex/dist/vuex.common.js";
import ImageUploader from "../../../components/shared/ImageUploader.vue";

export default {
  components: {
    benefitsCard,
    PersonalizedDialog,
    ImageUploader,
  },
  data() {
    return {
      selectedSubgroup: "Enviar a Todos",
      subgroups: ["Enviar a Todos"],
      selectedOption: "no",
      confirmOption: false,
      confirmDialog2: false,
      tabIndex: 0,
      benefitsList: [],
      tabs: ["Beneficios del Organizador"],
      color: colors,
      dialog: false,
      editSwitch: false,
      deleteSwitch: false,
      temporalId: "",
      formValid: false,
      form: [
        {
          value: null,
          id: "icon",
          rule: [],
        },
        {
          value: "",
          label: "Nombre comercial del beneficio",
          id: "name",
          rule: (v) => !!v || "Asignar nombre al beneficio",
        },
        {
          value: "",
          label: "URL del beneficio",
          id: "url",
          rule: (v) =>
            !!!v || /^(https?):\/\/[^\s/$.?#].[^\s]*$/.test(v) || "error",
        },
        {
          value: "",
          label: "Fecha de expiración",
          id: "expiredDate",
          rule: [],
        },
        {
          value: "",
          label: "Descripción e instrucción para usar beneficio",
          id: "description",
          rule: (v) =>
            v.length <= 150 || "Ha excedido la longitud máxima de caracteres",
        },
      ],
    };
  },
  computed: {
    dialogComputed() {
      return this.dialog;
    },
    editComputed() {
      return this.editSwitch;
    },
    minDate() {
      return new Date().toISOString().slice(0, 10);
    },
  },
  async created() {
    this.loading()
    try {
      await this.fetchSubgroups();
      await this.getBenefitsList();
    } catch (error) {
      console.log(error);
    } finally {
      this.loaded()
    }
  },
  methods: {
    ...mapActions("benefits", [
      "setBenefits",
      "getBenefits",
      "setBenefitsById",
      "deleteBenefit",
    ]),
    ...mapMutations("notification", ["show"]),
    ...mapMutations("loader", ["loading", "loaded"]),
    onOptionChange() {
      this.selectedOption = "yes";
      if (this.selectedOption === "yes") {
        this.confirmDialog2 = true;
      } else {
        this.selectedSubgroup = "";
      }
    },
    confirmYes() {
      this.confirmDialog2 = false;
      this.selectedOption = "yes";
      this.confirmOption = true;
    },
    confirmNo() {
      if (this.confirmOption == true) {
        this.confirmDialog2 = false;
      } else {
        this.selectedOption = "no";
        this.confirmDialog2 = false;
      }

      console.log("not");
    },
    async fetchSubgroups() {
      try {
        const response = await axios.get("/users/subgroups");

        this.subgroups = ["Enviar a Todos", ...response.data];
      } catch (error) {
        console.error("Error al obtener los subgrupos:", error);
      }
    },
    async fetchEmails(selectedSubgroup) {
      try {
        const response = await axios.get(
          `/users/emails?subgroup=${selectedSubgroup}`
        );
        return response.data;
      } catch (error) {
        console.error("Error fetching emails:", error);
        this.show({
          color: "error",
          text: "Error al obtener los correos electrónicos.",
        });
        return [];
      }
    },
    async createNewBenefit() {
      try {
        let formData = new FormData();
        let data = this.form.reduce((obj, item) => {
          obj[item.id] = item.value;
          return obj;
        }, {});

        data.sendNotification = this.selectedOption === "yes";

        if (data.sendNotification) {
          console.log("entra");
          try {
            const emails = await this.fetchEmails(this.selectedSubgroup);
            console.log("mails", emails);
            await this.axios.post("users/send-benefit-notification", {
              benefitTitle: data.name,
              emails: emails,
            });
          } catch (error) {
            console.log("no entra 2");

            console.log("error");
            this.show({
              color: "error",
              text: "Ya has creado 3 beneficios con notificaciones hoy. Inténtalo de nuevo mañana.",
            });

            return;
          }
        }
        formData.append("file", this.form[0].value);
        const location = await this.axios.post("/benefits/set-logo", formData);
        data.icon = location.data.location;

        let response = await this.setBenefits(data);
        if (response.status) {
          this.show({
            color: "success",
            text: response.message,
          });
          await this.getBenefitsList();
        } else {
          this.show({
            color: "error",
            text: response.message,
          });
        }
      } catch (error) {
        console.error(error);
      }
    },
    handleEdit(item) {
      let benefictObject = item;
      this.dialog = true;
      for (let i = 0; i < this.form.length; i++) {
        console.log(this.form[i].id);
        if (this.form[i].id == "expiredDate") {
          this.form[i].value = String(
            benefictObject[this.form[i].id]
          ).substring(0, 10);
        } else {
          this.form[i].value = benefictObject[this.form[i].id];
        }
      }
      console.log(this.form);
      this.editSwitch = true;
      this.temporalId = benefictObject._id;
    },

    async editBenefit() {
      console.log("EDIT");
      try {
        console.log(this.form);
        console.log(this.temporalId);

        if (typeof this.form[0].value == "object") {
          let formData = new FormData();
          let file = this.form[0].value;
          formData.append("file", file);
          let location = await this.axios.post("/benefits/set-logo", formData);
          this.form[0].value = location.data.location;
        }

        console.log(this.form);
        let data = [...this.form].reduce((obj, item) => {
          obj[item.id] = item.value;
          return obj;
        }, {});

        console.log(data);

        let response = await this.setBenefitsById({
          form: data,
          id: this.temporalId,
        });
        if (response.status) {
          this.show({
            color: "success",
            text: response.message,
          });
          await this.getBenefitsList();
          cleanForm();
        } else {
          this.show({
            color: "error",
            text: response.message,
          });
        }
      } catch (error) { }
    },

    handleIcon(file, resetState) {
      console.log(file);
      let index = [...this.form].findIndex((element) => element.id === "icon");
      console.log(index);
      this.form[index].value = file;
      console.log(this.form);
      resetState();
    },

    async getBenefitsList() {
      try {
        let response = await this.getBenefits();
        if (response) {
          this.benefitsList = response.data.data;
        }
      } catch (error) {
        console.log(error);
      }
    },

    async deleteRegist(benefitId) {
      try {
        let response = await this.deleteBenefit(benefitId);
        if (response.status) {
          this.show({
            color: "success",
            text: response.message,
          });
          await this.getBenefitsList();
        } else {
          this.show({
            color: "error",
            text: response.message,
          });
        }
      } catch (error) {
        console.log(error);
      }
      this.deleteSwitch = false;
    },

    cleanForm() {
      this.editSwitch = false;
      this.temporalId = "";
      for (let i = 0; i < this.form.length; i++) {
        this.form[i].value = "";
        if (this.form[i].id == "icon") {
          this.form[i].value = null;
        }
      }
    },
  },
};
</script>
